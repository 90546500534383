import React from "react";
import "./articleMentionsLegales.css";

function ArticleMentionsLegales() {
  return (
    <>
      <article className="container row px-2 mt-5 p-0 overflow-hidden">
        <ul className="list-unstyled col-12 col-lg-3 listForm rounded-3 p-0 mx-lg-3">
          <li className="HeaderTable border-bottom border-2 d-flex justify-content-center text-center  align-itmes-center py-2">
            Edition
          </li>
          <li className="border-bottom border-2 d-flex justify-content-center align-itmes-center py-2">
            SWITCH ENERGY
          </li>
          <li className="rounded-top-3 border-bottom border-2 d-flex justify-content-center  text-center align-itmes-center py-2">
            Marque de SAGITERRE SAS
          </li>
          <li className="rounded-top-3 border-bottom border-2 d-flex justify-content-center  text-center align-itmes-center py-2">
            2 rue Saint Hilaire 51100 REIMS
          </li>
          <li className="rounded-top-3 border-bottom border-2 d-flex justify-content-center  text-center align-itmes-center py-2">
            Siret : 49310555500038
            <br />
            RCS : Reims B 493 105 555
          </li>
          <li className="rounded-top-3 border-bottom border-2 d-flex justify-content-center  text-center align-itmes-center py-2">
            energy@chezswitch.fr
          </li>
        </ul>

        <ul className="list-unstyled containerList col-12 col-lg-4 listForm rounded-3 mx-lg-3 p-0">
          <li className="HeaderTable border-bottom border-2 d-flex justify-content-center align-itmes-center py-2">
            Conception
          </li>
          <li className="rounded-top-3 border-bottom border-2 d-flex justify-content-center text-center  align-itmes-center py-2">
            D13 Studio
          </li>
          <li className="rounded-top-3 border-bottom border-2 d-flex justify-content-center text-center  align-itmes-center py-2">
            57160 Scy-Chazelles
          </li>
          <li className="rounded-top-3 d-flex justify-content-center align-itmes-center text-center py-2">
            info@d13.fr
          </li>
        </ul>
        <ul className="list-unstyled containerList col-12 col-lg-3 listForm rounded-3 mx-lg-3 p-0">
          <li className="HeaderTable  border-bottom border-2 d-flex justify-content-center align-itmes-center py-2">
            Hébergement
          </li>
          <li className="rounded-top-3 border-bottom border-2 d-flex justify-content-center  text-center align-itmes-center py-2">
            INFOMANIAK NETWORK SA
          </li>
          <li className="rounded-top-3 border-bottom border-2 d-flex justify-content-center  text-center align-itmes-center py-2">
            Rue Eugène-Marziano 25
          </li>
          <li className="rounded-top-3 border-bottom border-2 d-flex justify-content-center  text-center align-itmes-center py-2">
            1227 Les Acacias
          </li>
          <li className="rounded-top-3 d-flex justify-content-center  text-center align-itmes-center py-2">
            Suisse
          </li>
        </ul>
      </article>
      <article id="articleAnalytics" className="mx-2 rounded-3">
        <h3 className="mt-2 ps-2">
          Utilisation des services d'analyse de Google Analytics
        </h3>
        <p className="ps-3 fw-light">
          Ce site utilise Google Analytics, un service d'analyse de site
          internet fourni par Google Inc. Google Analytics utilise des cookies,
          qui sont des fichiers texte placés sur votre ordinateur, pour aider le
          site internet à analyser l'utilisation du site par ses utilisateurs.
          Les données générées par les cookies concernant votre utilisation du
          site (y compris votre adresse IP) seront transmises et stockées par
          Google sur des serveurs situés aux états-Unis. Google utilisera cette
          information dans le but d'évaluer votre utilisation du site, de
          compiler des rapports sur l'activité du site à destination de son
          éditeur et de fournir d'autres services relatifs à l'activité du site
          et à l'utilisation d'Internet.
          <br />
          Google est susceptible de communiquer ces données à des tiers en cas
          d'obligation légale ou lorsque ces tiers traitent ces données pour le
          compte de Google, y compris notamment l'éditeur de ce site.
          <br />
          Google ne recoupera pas votre adresse IP avec toute autre donnée
          détenue par Google. Vous pouvez désactiver l'utilisation de cookies en
          sélectionnant les paramètres appropriés de votre navigateur.
          Cependant, une telle désactivation pourrait empêcher l'utilisation de
          certaines fonctionnalités de ce site.
          <br />
          En utilisant ce site internet, vous consentez expressément au
          traitement de vos données nominatives par Google dans les conditions
          et pour les finalités décrites ci-dessus.
        </p>
      </article>
    </>
  );
}

export default ArticleMentionsLegales;
